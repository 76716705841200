.loginStyle {
    display: flex;
    justify-content: center;
    margin: 10% auto;
    border-radius: 5px;
    padding: 2rem;
    width: max-content;
}

html {
    background-color: #ffff;
}

.light {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffff;
}

.dark {
    background-color: #000000;
    box-shadow: 0 0 10px 0 rgb(255 255 255 / 50%);
}

.switchDark {
    background-image: url('https://img.icons8.com/ios/50/000000/sun--v1.png');
    background-size: 50%;
    background-color: #ffffff;
}

.switchLight{
    background-image: url('https://img.icons8.com/ios/50/000000/moon--v1.png');
    background-size: 50%;
    background-color: #000000;
}