.issueTitle {
    display: flex;
    font-weight: bold;
}

.issueDark {
    color: rgba(255, 255, 255, 0.85);
}

.issueLight {
    color: rgba(0, 0, 0, 0.88);
}