.switch-label {
    width: 5rem;
    height: 2rem;
    position: relative;
    display: block;
    background: #ebebeb;
    border-radius: 2rem;
    box-shadow: inset 0px 5px 15px rgba(0, 0, 0, 0.4), inset opx -5 px 15px rgba(255, 255, 255, 0.4);
    cursor: pointer;
    transition: .3s;
}

.switch-label::after {
    content: "";
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    top: .25rem;
    left: .15rem;
    background: linear-gradient(180deg, #ffcc89, #d8860b);
    border-radius: 1rem;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    transition: .3s;
}

.switch {
    width: 0px;
    height: 0px;
    visibility: hidden;
    position: absolute;
}

.switch:checked + .switch-label {
    background: #242424;
}

.switch:checked + .switch-label::after {
    left: calc(100% - .1rem);
    transform: translateX(-100%);
    background: linear-gradient(180deg, #777, #3a3a3a);
}

.switch-label:active::after {
    width: 2rem;
}

.switch-label svg {
    position: absolute;
    left: .3rem;
    width: 1.2rem;
    top: 20%;
    z-index: 100;
}

.switch-label svg.sun {
    fill: #fff;
    transition: .3s;
}

.switch-label svg.moon {
    left: 3.5rem;
    fill: #7e7e7e;
    transition: .3s;
}

.switch:checked + .switch-label svg.sun {
    fill: #7e7e7e;
}

.switch:checked + .switch-label svg.moon {
    fill: #fff;
}